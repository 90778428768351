import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
// import {
//   BID_PROCESS_NAME,
//   INQUIRY_PROCESS_NAME,
//   getAllTransitionsForEveryProcess,
// } from '../../transactions/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );
// ================ Action types ================ //
export const FETCH_ORDERS_OR_SALES_REQUEST =
  'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS =
  'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR =
  'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';
export const FETCH_ACCEPTED_SUCCESS = 'app/InboxPage/FETCH_ACCEPTED_SUCCESS';
// ================ Reducer ================ //
const resultIds = data => data.data.map(l => l.id);

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));
const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  listingRefs: [],
};
export default function inboxPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ACCEPTED_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        listingRefs: resultIds(payload.data),
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchInProgress: false,
        fetchOrdersOrSalesError: payload,
      };
    default:
      return state;
  }
}
// ================ Action creators ================ //
const fetchOrdersOrSalesRequest = () => ({
  type: FETCH_ORDERS_OR_SALES_REQUEST,
});
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// const fetchAcceptedSuccess = response => ({
//   type: FETCH_ACCEPTED_SUCCESS,
//   payload: response,
// });
// ================ Thunks ================ //
const INBOX_PAGE_SIZE = 10;
export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab, type } = params;

  const onlyFilterValues = {
    inquiry: { tab: 'inquiry', only: null, processNames: 'default-booking' },
    myBids: { tab: 'myBids', only: 'order', processNames: 'default-bid' },
    projectBids: {
      tab: 'projectBids',
      only: 'sale',
      processNames: 'default-bid',
    },
    accepted: {
      tab: 'accepted',
      only: null,
      processNames: ['default-bid', 'default-accept-bid'],
    },
    declined: {
      tab: 'declined',
      only: null,
      processNames: ['default-bid', 'default-accept-bid'],
    },
  };
  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter?.tab) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }
  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  const apiQueryParams = {
    only: onlyFilter?.only,
    processNames: onlyFilter.processNames,
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'processName',
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
      'protectedData',
      'metadata',
    ],
    'fields.listing': ['title', 'availabilityPlan', 'publicData'],
    'fields.user': [
      'profile.displayName',
      'profile.abbreviatedName',
      'profile.publicData',
    ],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    // TODO: Maybe better to move transaction to separate transaction process?
    perPage:
      tab === 'accepted' || tab === 'declined'
        ? INBOX_PAGE_SIZE * 2
        : INBOX_PAGE_SIZE,
  };

  if (tab === 'inquiry') {
    apiQueryParams.lastTransitions = ['transition/inquire'];
  } else if (tab === 'myBids') {
    apiQueryParams.lastTransitions = [
      'transition/default-bid',
      'transition/request-payment',
      'transition/installment-payment',
      'transition/request-payment-after-inquiry',
      'transition/inquire',
    ];
  } else if (tab === 'projectBids') {
    apiQueryParams.lastTransitions = ['transition/inquire'];
  } else if (tab === 'accepted') {
    apiQueryParams.lastTransitions = [
      'transition/pro-accepted',
      'transition/pro-accept-job',
      'transition/sent-milestone-installment',
      'transition/sent-milestone-two-installment',
      'transition/milestone-auto-complete',
      'transition/auto-payout',
      'transition/confirm-payment',
      'transition/sent-milestone',
      'transition/milestone-complete',
      'transition/complete',
      'transition/sent-milestone-final-installment',
      'transition/milestone-final-auto-complete',
      'transition/review-1-by-provider',
      'transition/review-2-by-provider',
      'transition/review-1-by-customer',
      'transition/review-2-by-customer',
      'transition/installment-complete',
      // "transition/Milestone-confirm-payment",
    ];
  } else if (tab === 'declined') {
    apiQueryParams.lastTransitions = [
      'transition/decline-bid',
      'transition/decline',
      'transition/pro-declined',
      'transition/expire',
    ];
  }
  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
